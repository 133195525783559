import React, { useContext } from 'react';
import {
	FaAngleRight,
	FaAngleLeft,
	FaSignOutAlt,
	FaBars
} from 'react-icons/fa';
import { NavLink } from "react-router-dom";
import { authContext } from "./../authContext/authContext"


const ICON_SIZE = 20;

function Navbar({ visible, show }) {

    const { logout } = useContext(authContext);


	async function handleSalir () {
		try {
			console.log("hola")
			await logout();
		} catch (error) {
			console.log("hola")
		}
	}

	return (
		<div className='min-vertical'>
			<div className='vertical'>
				<div className="mobile-nav">
					<button
						className="mobile-nav-btn"
						onClick={() => show(!visible)}
					>
						<FaBars size={24} />
					</button>
				</div>
				<nav className={!visible ? 'navbar navH' : ''}>
					<button
						type="button"
						className="nav-btn"
						onClick={() => show(!visible)}
					>
						{!visible
							? <FaAngleRight size={30} /> : <FaAngleLeft size={30} />}
					</button>
					<div>
						<NavLink
							className="logo"
							to="/"
						>
							<img
								src={require("./../img/log.png")}
								alt="logo"
							/>
						</NavLink>
						<div className="links nav-top">
							<NavLink to="/Inicio" className="nav-link">
								<span>Inicio</span>
							</NavLink>
							<NavLink to="/Categoria" className="nav-link">
								<span>Categorías</span>
							</NavLink>
							<NavLink to="/Productos" className="nav-link">
								<span>Productos</span>
							</NavLink>
						</div>
					</div>

					<div className="links">
						<button onClick={()=>handleSalir()} className='btn w-100'>
							<FaSignOutAlt size={ICON_SIZE} />
							<p className='m-0 ms-2'>
								Salir
							</p>
						</button>
					</div>
				</nav>
			</div>
		</div>
	);
}

export default Navbar;
