// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAWwXQO2u6guBxXHNxAFqCqySGsGqtnMj0",
  authDomain: "ecomerce-3806c.firebaseapp.com",
  projectId: "ecomerce-3806c",
  storageBucket: "ecomerce-3806c.appspot.com",
  messagingSenderId: "742019617514",
  appId: "1:742019617514:web:2ac7ba99fa950985d83d93"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const storage = getStorage(app)
export async function uploadFile(file){
  const storageRef = ref(storage, v4())
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef)
  return url;
}

export default db;
export const auth = getAuth(app);