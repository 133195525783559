import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Navbar from "../componentes/Navbar";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db, { uploadFile } from "../firebase/firebase";
import Select from "react-select";



export const Producto = () => {

    const params = useParams()

    const [loading, setLoading] = useState(false)
    const [navVisible, showNavbar] = useState(true);

    const [varcategoria, setVarcategoria] = useState("")
    const [varcolor, setVarcolor] = useState([])
    const [vartamano, setVartamano] = useState([])
    const [vardescuento, setVardescuento] = useState("")
    const [vargaleria, setVargaleria] = useState([])
    const [varnombre, setVarnombre] = useState("")
    const [varperfil, setVarperfil] = useState("")
    const [varprecio, setVarprecio] = useState(0)
    const [varunidades, setVarunidades] = useState([])
    const [listacategorias, setListacategorias] = useState([]);
    const [fotoperfil, setFotoperfil] = useState(null)
    const [fotodestacadas, setFotodestacadas] = useState(null)

    const options = [
        { value: "Amarilla oscura", label: "Amarilla oscura" },
        { value: "Gris oscuro", label: "Gris oscuro" },
        { value: "Azul claro", label: "Azul claro" },
        { value: "Azul", label: "Azul" },
        { value: "Verde", label: "Verde" },
        { value: "Azul oscuro", label: "Azul oscuro" },
        { value: "Rosa claro", label: "Rosa claro" },
        { value: "Rosado", label: "Rosado" },
        { value: "Púrpura claro", label: "Púrpura claro" },
        { value: "Gris", label: "Gris" },
        { value: "Marrón", label: "Marrón" },
        { value: "Negro", label: "Negro" },
        { value: "Beige", label: "Beige" },
        { value: "Blanco", label: "Blanco" },
      ];

      const tamano = [
        { value: "35", label: "35" },
        { value: "36", label: "36" },
        { value: "37", label: "37" },
        { value: "38", label: "38" },
        { value: "39", label: "39" },
        { value: "XS", label: "XS" },
        { value: "S", label: "S" },
        { value: "M", label: "M" },
        { value: "L", label: "L" },
        { value: "XL", label: "XL" },
      ];




    useEffect(() => {

        async function LeerDatos() {

            let id = params.id

            const docRef = doc(db, "Producto", `${id}`);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {

                console.log(docSnap.data())

                setVarcategoria(docSnap.data().categoria)
                if(docSnap.data().color.length !== 0){
                    let arrayColor = new Array
                    docSnap.data().color.map((e) => {
                        arrayColor.push({value: e, label: e})
                    })
                    setVarcolor(arrayColor)
                }else{
                    setVarcolor([])
                }
                setVargaleria(docSnap.data().galeria)
                setVarnombre(docSnap.data().nombre)
                setVarperfil(docSnap.data().perfil)
                setVarprecio(docSnap.data().precio)
                setVardescuento(docSnap.data().descuento)
                if(docSnap.data().tamano.length !== 0){
                    let arrayColor = new Array
                    docSnap.data().tamano.map((e) => {
                        arrayColor.push({value: e, label: e})
                    })
                    setVartamano(arrayColor)
                }else{
                    setVarcolor([])
                }


            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }

        }

        async function LeerCategorias() {

            const docRef = doc(db, "Categoria", "mxjG0SngAiPq8kkSYIxs");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            console.log(docSnap.data())
            docSnap.data().Categorias.forEach(element => {
                lote.push(element)
            });

            setListacategorias(lote)

        }

        LeerCategorias();

        LeerDatos();

    }, [])




    async function funcionfoto() {
        try {
            const result = await uploadFile(fotoperfil)
            return result
        } catch (error) {
            console.error(error)
        }
    }

    async function functionGaleria() {
        try {
            let ArrayTemporal = new Array
            for (let index = 0; index < 4; index++) {
                const result = await uploadFile(fotodestacadas[index]).then((e) => {
                    ArrayTemporal.push(e)
                })
            }
            return ArrayTemporal
        } catch (error) {
            console.error(error)
        }
    }


    async function Actualizar(categoria, color, descuento, galeria, nombre, perfil, precio, tamano ){

            
        const docRef = doc(db, "Producto", `${params.id}`);

        await updateDoc(docRef, {
            categoria: categoria,
            color: color,
            descuento: descuento,
            galeria: galeria,
            nombre: nombre,
            perfil: perfil,
            precio: precio,
            tamano: tamano
        })


    }


    const hanldesubmit = (e) => {

        e.preventDefault()

        setLoading(true)

        let colorfin
        let tamano


        if(varcolor.length !== 0){
            let colorarry = new Array
            for (let index = 0; index < varcolor.length; index++) {
                colorarry.push(varcolor[index].value)
            }
            colorfin = colorarry
        }else{
            colorfin = []
        }



        if(vartamano.length !== 0){
            let tamanoarry = new Array
            for (let index = 0; index < vartamano.length; index++) {
                tamanoarry.push(vartamano[index].value)
            }
            tamano = tamanoarry
        }else{
            tamano = []
        }




        if(fotoperfil == null){
        
            funcionfoto().then((perfil)=>{
                Actualizar(varcategoria, colorfin, vardescuento, vargaleria, varnombre, perfil, varprecio, tamano).then((listo)=>{
                    window.location.reload(true)
                })
            })

        }else if(fotodestacadas == null){

            functionGaleria().then((galeria)=>{
                Actualizar(varcategoria, colorfin, vardescuento, galeria, varnombre, varperfil, varprecio, tamano).then((listo)=>{
                    window.location.reload(true)
                })
            })

        }else{

            funcionfoto().then((perfil)=>{

                functionGaleria().then((galeria)=>{
                    Actualizar(varcategoria, colorfin, vardescuento, galeria, varnombre, perfil, varprecio, tamano).then((listo)=>{
                        window.location.reload(true)
                    })
                })

            })

        }


    }


    return (
        <div>
            <Navbar visible={navVisible} show={showNavbar} />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                {
                    loading == true ?
                        <div className={!navVisible ? "Loading w-100" : "Loading"}>
                            <h1 data-text="Cargando..." className="cargando">Cargando...</h1>
                        </div>
                        :
                        <></>
                }
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="cardd p-4">
                                <h3 className="text-center">
                                    Editar Producto
                                </h3>
                                <form onSubmit={hanldesubmit}>
                                    <div className="mb-3">
                                        <label className="form-label">Nombre</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            value={varnombre}
                                            onChange={(e) => setVarnombre(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Precio</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="precio"
                                            name="precio"
                                            value={varprecio}
                                            onChange={(e) => setVarprecio(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Descuento</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="descuento"
                                            name="descuento"
                                            value={vardescuento}
                                            onChange={(e) => setVardescuento(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Foto</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => setFotoperfil(e.target.files[0])}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Fotos Galeria</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => setFotodestacadas(e.target.files)}
                                            multiple
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label>Colores:</label>
                                        <Select isMulti value={varcolor} onChange={(e) => setVarcolor(e)} options={options} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Tanmaños:</label>
                                        <Select isMulti value={vartamano} onChange={(e) => setVartamano(e)} options={tamano} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Categoria</label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="categoria"
                                            name="categoria"
                                            value={varcategoria}
                                            onChange={(e) => setVarcategoria(e.target.value)}
                                        >
                                            <option value="selecionar">Selecionar</option>
                                            {
                                                listacategorias.map((e, index) => (
                                                    <option key={index} value={e}>{e}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <button type="submit" value="Submit" className="btn btn-primary w-100">
                                        Editar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}