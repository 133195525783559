import React, { useEffect, useState } from "react";
import Navbar from "../componentes/Navbar";
import db, { uploadFile } from "../firebase/firebase";
import { doc, getDoc, collection, addDoc } from "firebase/firestore";
import swal from 'sweetalert';
import Select from "react-select";



export const Index = () => {

    const [loading, setLoading] = useState(false)
    const [navVisible, showNavbar] = useState(true);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedTamano, setSelectedTamano] = useState([]);
    const [fotoperfil, setFotoperfil] = useState(null)
    const [fotodestacadas, setFotodestacadas] = useState(null)
    const [listacategorias, setListacategorias] = useState([]);

    const options = [
        { value: "Amarilla oscura", label: "Amarilla oscura" },
        { value: "Gris oscuro", label: "Gris oscuro" },
        { value: "Azul claro", label: "Azul claro" },
        { value: "Azul", label: "Azul" },
        { value: "Verde", label: "Verde" },
        { value: "Azul oscuro", label: "Azul oscuro" },
        { value: "Rosa claro", label: "Rosa claro" },
        { value: "Rosado", label: "Rosado" },
        { value: "Púrpura claro", label: "Púrpura claro" },
        { value: "Gris", label: "Gris" },
        { value: "Marrón", label: "Marrón" },
        { value: "Negro", label: "Negro" },
        { value: "Beige", label: "Beige" },
        { value: "Blanco", label: "Blanco" },
      ];

      const tamano = [
        { value: "35", label: "35" },
        { value: "36", label: "36" },
        { value: "37", label: "37" },
        { value: "38", label: "38" },
        { value: "39", label: "39" },
        { value: "XS", label: "XS" },
        { value: "S", label: "S" },
        { value: "M", label: "M" },
        { value: "L", label: "L" },
        { value: "XL", label: "XL" },
      ];


    useEffect(() => {

        async function LeerCategorias() {

            const docRef = doc(db, "Categoria", "mxjG0SngAiPq8kkSYIxs");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            //console.log(docSnap.data())
            docSnap.data().Categorias.forEach(element => {
                lote.push(element)
            });

            setListacategorias(lote)

        }

        LeerCategorias();

    }, [])



    async function funcionfoto() {
        try {
            const result = await uploadFile(fotoperfil)
            return result
        } catch (error) {
            console.error(error)
        }
    }

    async function functionGaleria() {
        try {
            let ArrayTemporal = new Array
            for (let index = 0; index < 4; index++) {
                const result = await uploadFile(fotodestacadas[index]).then((e) => {
                    ArrayTemporal.push(e)
                })
            }
            return ArrayTemporal
        } catch (error) {
            console.error(error)
        }
    }

    async function addProducto( categoria, color, descuento, galeria, nombre, perfil, precio, tamano) {
        const docaddproducto = await addDoc(collection(db, "Producto"), {
            categoria: categoria,
            color: color,
            descuento: descuento,
            galeria: galeria,
            nombre: nombre,
            perfil: perfil,
            precio: precio,
            tamano: tamano
        });
    }



    const handleSubmit = (e) => {


        e.preventDefault()

        setLoading(true)

        const nombre = e.target.nombre.value
        const precio = e.target.precio.value
        let descuento = e.target.descuento.value
        let colores = selectedOption
        const categoria = e.target.categoria.value
        let tamano = selectedTamano



        if(descuento.length == 0){
            descuento = ""
        }



        if(colores.length == 0){
            colores = []
        }else{
            let arrayColor = new Array
            for (let index = 0; index < colores.length; index++) {
                arrayColor.push(colores[index].value);
            }
            colores = arrayColor
        }


        if(tamano.length == 0){
            tamano = []
        }else{
            let arrayColor = new Array
            for (let index = 0; index < tamano.length; index++) {
                arrayColor.push(tamano[index].value);
            }
            tamano = arrayColor
        }



        if(categoria == "selecionar"){
            setLoading(false)
            swal({
                text: "Selecione una categoria",
                icon: "error",
                timer: 2000
            })
        }else{
            funcionfoto().then((perfil)=>{
                functionGaleria().then((galeria)=>{
                    addProducto(categoria, colores, descuento, galeria, nombre, perfil, precio, tamano).then((fial)=>{
                        window.location.reload(true)
                    })

                })
            })
        }



    }



    return (
        <div>
            <Navbar visible={navVisible} show={showNavbar} />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                {
                    loading == true ?
                        <div className={!navVisible ? "Loading w-100" : "Loading"}>
                            <h1 data-text="Cargando..." className="cargando">Cargando...</h1>
                        </div>
                        :
                        <></>
                }
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="cardd p-4">
                                <h2 className="text-center">Crear</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label className="form-label">Nombre</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Precio</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="precio"
                                            name="precio"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Descuento</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="descuento"
                                            name="descuento"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Foto</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            required
                                        onChange={(e) => setFotoperfil(e.target.files[0])}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Fotos Galeria</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            required
                                            onChange={(e) => setFotodestacadas(e.target.files)}
                                            multiple
                                        />
                                    </div>
                                    <div className="mb-3">
                                    <label className="form-label">Colores:</label>
                                        <Select isMulti onChange={(e) => setSelectedOption(e)} options={options} />
                                    </div>
                                    <div className="mb-3">
                                    <label className="form-label">Tamaños:</label>
                                        <Select isMulti onChange={(e) => setSelectedTamano(e)} options={tamano} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Categoria</label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="categoria"
                                            name="categoria"
                                        >
                                            <option value="selecionar">Selecionar</option>
                                            {
                                                listacategorias.map((e, index) => (
                                                    <option key={index} value={e}>{e}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <button type="submit" value="Submit" className="btn btn-primary w-100">
                                        Enviar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
