import React, { useEffect, useState } from "react";
import Navbar from "../componentes/Navbar";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db, { uploadFile } from "../firebase/firebase";
import Select from "react-select";


export const FavoritoUno = () => {

    const [loading, setLoading] = useState(false)
    const [navVisible, showNavbar] = useState(true);


    const [varcategoria, setVarcategoria] = useState("")
    const [varcolor, setVarcolor] = useState([])
    const [vardescripcion, setVardescripcion] = useState("")
    const [vardescuento, setVardescuento] = useState("")
    const [vargaleria, setVargaleria] = useState([])
    const [varnombre, setVarnombre] = useState("")
    const [varperfil, setVarperfil] = useState("")
    const [varprecio, setVarprecio] = useState(0)
    const [varunidades, setVarunidades] = useState([])
    const [listacategorias, setListacategorias] = useState([]);
    const [fotoperfil, setFotoperfil] = useState(null)
    const [fotodestacadas, setFotodestacadas] = useState(null)

    const options = [
        { value: "Blanco", label: "Blanco" },
        { value: "Azul", label: "Azul" },
        { value: "Rojo", label: "Rojo" },
        { value: "Cafe", label: "Cafe" },
        { value: "Morado", label: "Morado" },
        { value: "Amarillo", label: "Amarillo" },
        { value: "Negro", label: "Negro" },
        { value: "Plata o Gris", label: "Plata o Gris" },
        { value: "Verde", label: "Verde" },
        { value: "Rosado", label: "Rosado" },
      ];



    useEffect(() => {

        async function LeerDatos() {

            setLoading(true)

            const docRef = doc(db, "FavoritoUno", "KpC2UpSY1fV4IaRCmzVd");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {

                console.log(docSnap.data())

                setVarcategoria(docSnap.data().categoria)
                if(docSnap.data().color.length !== 0){
                    let arrayColor = new Array
                    docSnap.data().color.map((e) => {
                        arrayColor.push({value: e, label: e})
                    })
                    setVarcolor(arrayColor)
                }else{
                    setVarcolor([])
                }
                setVardescripcion(docSnap.data().descripcion)
                setVardescuento(docSnap.data().descuento)
                setVargaleria(docSnap.data().galeria)
                setVarnombre(docSnap.data().nombre)
                setVarperfil(docSnap.data().perfil)
                setVarprecio(docSnap.data().precio)
                if(docSnap.data().unidades == 0){
                    setVarunidades([{ cantidad: "", monto: "" }])
                }else{
                    setVarunidades(docSnap.data().unidades)
                }


                setLoading(false)

            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }

        }

        async function LeerCategorias() {

            const docRef = doc(db, "Categoria", "mxjG0SngAiPq8kkSYIxs");
            const docSnap = await getDoc(docRef);
            let lote = new Array
            console.log(docSnap.data())
            docSnap.data().Categorias.forEach(element => {
                lote.push(element)
            });

            setListacategorias(lote)

        }

        LeerCategorias();

        LeerDatos();

    }, [])


    const handleServiceChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...varunidades];
        list[index][name] = value;
        setVarunidades(list);
    };

    const handleServiceRemove = (index) => {
        const list = [...varunidades];
        list.splice(index, 1);
        setVarunidades(list);
    };

    const handleServiceAdd = () => {
        setVarunidades([...varunidades, { cantidad: "", monto: "" }]);
    };


    async function funcionfoto() {
        try {
            const result = await uploadFile(fotoperfil)
            return result
        } catch (error) {
            console.error(error)
        }
    }

    async function functionGaleria() {
        try {
            let ArrayTemporal = new Array
            for (let index = 0; index < fotodestacadas.length; index++) {
                const result = await uploadFile(fotodestacadas[index]).then((e) => {
                    ArrayTemporal.push(e)
                })
            }
            return ArrayTemporal
        } catch (error) {
            console.error(error)
        }
    }


    async function Actualizar(categoria, color, descuento, galeria, nombre, perfil, precio, unidades ){

            
        const docRef = doc(db, "FavoritoUno", "KpC2UpSY1fV4IaRCmzVd");

        await updateDoc(docRef, {
            categoria: categoria,
            color: color,
            descuento: descuento,
            galeria: galeria,
            nombre: nombre,
            perfil: perfil,
            precio: precio,
            unidades: unidades 
        })


    }


    const hanldesubmit = (e) => {

        e.preventDefault()

        setLoading(true)

        let colorfin
        let unidadesfin


        if(varcolor.length !== 0){
            let colorarry = new Array
            for (let index = 0; index < varcolor.length; index++) {
                colorarry.push(varcolor[index].value)
            }
            colorfin = colorarry
        }else{
            colorfin = []
        }

        if(varunidades[0].cantidad.length == 0){
            unidadesfin = []
        }else{
            unidadesfin = varunidades.filter((item) => item.cantidad.length !== 0)
        }





        if(fotoperfil == null){
        
            funcionfoto().then((perfil)=>{
                Actualizar(varcategoria, colorfin, vardescuento, vargaleria, varnombre, perfil, varprecio, unidadesfin).then((listo)=>{
                    window.location.reload(true)
                })
            })

        }else if(fotodestacadas == null){

            functionGaleria().then((galeria)=>{
                Actualizar(varcategoria, colorfin, vardescuento, galeria, varnombre, varperfil, varprecio, unidadesfin).then((listo)=>{
                    window.location.reload(true)
                })
            })

        }else{

            funcionfoto().then((perfil)=>{

                functionGaleria().then((galeria)=>{
                    Actualizar(varcategoria, colorfin, vardescuento, galeria, varnombre, perfil, varprecio, unidadesfin).then((listo)=>{
                        window.location.reload(true)
                    })
                })

            })

        }


    }

    return (

        <div>
            <Navbar visible={navVisible} show={showNavbar} />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                {
                    loading == true ?
                        <div className={!navVisible ? "Loading w-100" : "Loading"}>
                            <h1 data-text="Cargando..." className="cargando">Cargando...</h1>
                        </div>
                        :
                        <></>
                }
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="cardd p-4">
                                <h3 className="text-center">
                                    Favorito Uno
                                </h3>
                                <form onSubmit={hanldesubmit}>
                                    <div className="mb-3">
                                        <label className="form-label">Nombre</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            value={varnombre}
                                            onChange={(e) => setVarnombre(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Precio</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="precio"
                                            name="precio"
                                            value={varprecio}
                                            onChange={(e) => setVarprecio(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Descuento</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="descuento"
                                            name="descuento"
                                            value={vardescuento}
                                            onChange={(e) => setVardescuento(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Descripcion</label>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="descripcion"
                                            name="descripcion"
                                            value={vardescripcion}
                                            onChange={(e) => setVardescripcion(e.target.value)}
                                            required
                                        >
                                        </textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Foto</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => setFotoperfil(e.target.files[0])}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Fotos Galeria</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => setFotodestacadas(e.target.files)}
                                            multiple
                                        />
                                    </div>
                                    <div>
                                        <h4 className="text-center">
                                            Colores:
                                        </h4>
                                    </div>
                                    <div className="mb-3">
                                        <Select isMulti value={varcolor} onChange={(e) => setVarcolor(e)} options={options} />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Categoria</label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            id="categoria"
                                            name="categoria"
                                            value={varcategoria}
                                            onChange={(e) => setVarcategoria(e.target.value)}
                                        >
                                            <option value="selecionar">Selecionar</option>
                                            {
                                                listacategorias.map((e, index) => (
                                                    <option key={index} value={e}>{e}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <h2 className="text-center">
                                            Promociones
                                        </h2>
                                    </div>
                                    <div className="mb-3">
                                        {varunidades.map((singleService, index) => (
                                            <div key={index} className="">
                                                <div className="mb-3 p-2 borderItinerario">
                                                    <label className="form-label">Unidades</label>
                                                    <input
                                                        className="form-control"
                                                        name="cantidad"
                                                        type="text"
                                                        id="cantidad"
                                                        value={singleService.cantidad}
                                                        onChange={(e) => handleServiceChange(e, index)}
                                                    />
                                                    <label className="form-label mt-3">Al predio de</label>
                                                    <input
                                                        className="form-control"
                                                        name="monto"
                                                        type="number"
                                                        id="montoo"
                                                        value={singleService.monto}
                                                        onChange={(e) => handleServiceChange(e, index)}
                                                    />
                                                    {varunidades.length !== 1 && (
                                                        <button
                                                            type="button"
                                                            onClick={() => handleServiceRemove(index)}
                                                            className="btn btn-danger m-auto d-block mt-2"
                                                        >
                                                            <span>Eliminar</span>
                                                        </button>
                                                    )}
                                                </div>
                                                <div>
                                                    {varunidades.length - 1 === index && varunidades.length < 8 && (
                                                        <button
                                                            type="button"
                                                            onClick={handleServiceAdd}
                                                            className="btn btn-primary mt-3 m-auto d-block"
                                                        >
                                                            <span>Agregar Intinerario</span>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <button type="submit" value="Submit" className="btn btn-primary w-100">
                                        Editar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )


}